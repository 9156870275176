/*======================================*/
/*---------------- Index ---------------*/
/*======================================*/
/*
  1. Imports mixins
  2. Font CSS
  3. Reset Default CSS
  4. Animation CSS
  5. Common CSS
     -> Loader CSS
     -> Accessibility CSS
  6. Form Elemtnt CSS
  7. Header CSS
  8. Footer CSS
  9. Content CSS
  10. Admin CSS
  11. Profile CSS
  12. Table CSS
  13. Modal CSS
  14. Alert CSS
  15. whiteboard CSS
  16. Accordion CSS
  17. Tabulation CSS
  18. Gallery CSS
  19. Carousel CSS
*/

/* Imports mixins */
@import "./variables";
@import "./mixins";
@import "./prefixes";
@import "./flaticon";
@import "./ejs";
@import "./metro";
@import "./flag";

/* @import "~@angular/material/prebuilt-themes/deeppurple-amber.css"; */
/* Imports mixins Ends */

/*Reset Default CSS*/
*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  @include box-sizing(border-box);
}
html {
  font-size: 16px;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
body {
  margin: 0;
  padding: 0;
  font-family: var(--default-font-family);
  font-weight: normal;
  font-size: 0.85rem;
  color: var(--black-color);
  background-color: var(--background-color);
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
nav,
div,
span,
img,
a,
p,
footer,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  text-decoration: none;
  list-style: none;
}
a,
button {
  color: var(--black-color);
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}
img {
  object-position: top;
}
/*reset default css Ends*/

/* Animation CSS */

/* Animation CSS Ends */

/* Common CSS */
h1 {
  font-size: 1.75rem;
  font-weight: 500;
  word-break: break-word;
}
h2 {
  font-size: 1.5rem;
  font-weight: 500;
  word-break: break-word;
}
h3 {
  font-size: 1.25rem;
  font-weight: 500;
  word-break: break-word;
}
h4 {
  font-size: 1.125rem;
  font-weight: 500;
  word-break: break-word;
}
h5 {
  font-size: 1rem;
  font-weight: 500;
  word-break: break-word;
}
h6 {
  font-size: 0.9rem;
  font-weight: 500;
  word-break: break-word;
}
p {
  font-size: 0.875rem;
  word-break: break-word;
}
.container {
  overflow: auto;
}
.font-12 {
  font-size: 0.75rem !important;
}
.font-14 {
  font-size: 0.875rem !important;
}
.font-16 {
  font-size: 1rem !important;
}
.font-18 {
  font-size: 1.125rem !important;
}
.font-20 {
  font-size: 1.25rem !important;
}
.font-24 {
  font-size: 1.5rem !important;
}
.font-32 {
  font-size: 2rem !important;
}
.font-40 {
  font-size: 2.5rem !important;
}
.font-60 {
  font-size: 3.75rem !important;
}
.font-100 {
  font-size: 6.25rem !important;
}
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
.font-white {
  color: var(--white-color) !important;
}
.font-black {
  color: var(--black-color) !important;
}
.font-light-gray {
  color: var(--light-gray-color) !important;
}
.font-gray {
  color: var(--gray-color) !important;
}
.font-dark-gray {
  color: var(--dark-gray-color) !important;
}
.font-success {
  color: var(--success-color) !important;
}
.font-danger {
  color: var(--danger-color) !important;
}
.font-light-warning {
  color: var(--light-warning-color) !important;
}
.font-warning {
  color: var(--warning-color) !important;
}
.bg-white {
  background-color: var(--white-color);
}
.bg-black {
  background-color: var(--black-color);
}
.bg-dark-white {
  background-color: var(--dark-white-color);
}
.bg-light-gray {
  background-color: var(--light-gray-color);
}
.bg-gray {
  background-color: var(--gray-color);
}
.bg-dark-gray {
  background-color: var(--dark-gray-color);
}
.bg-success {
  background-color: var(--success-color);
}
.bg-danger {
  background-color: var(--danger-color);
}
.bg-very-light-warning {
  background-color: var(--very-light-warning-color);
}
.w-80 {
  width: 80%;
}
.h-100px {
  height: 6.25rem;
}
.minh-100 {
  min-height: 100% !important;
}
.mxh-115px {
  max-height: 7.1875rem;
}
.mxh-80vh {
  max-height: 80vh;
}
.mw-auto {
  min-width: auto !important;
  max-width: auto !important;
}
.mw-7 {
  min-width: 7rem;
}
.border-light-gray {
  border: 1px solid var(--light-gray-color) !important;
}
.border-gray {
  border: 1px solid var(--gray-color) !important;
}
.border-black {
  border: 1px solid var(--black-color) !important;
}
.border-img-shadow {
  padding: 1px;
  border: 2px solid var(--light-gray-color) !important;
  border-color: var(--light-gray-color) !important;
}
.border-img-gray {
  padding: 1px;
  border: 2px solid var(--gray-color) !important;
  border-color: var(--gray-color) !important;
}
.border-img-white {
  padding: 1px;
  border: 2px solid var(--light-gray-color) !important;
  border-color: var(--white-color) !important;
  border-radius: 50%;
}
.theme-color {
  color: $theme-color;
}
.shadow-dark {
  @include box-shadow(0 0 1rem $medium-gray-color);
}
.shadow-dark-4 {
  @include box-shadow(0 0 0.4rem $medium-gray-color);
}
.bold {
  font-weight: bold !important;
}
.fix-width-90px {
  width: 5.625rem !important;
}
.fix-height-240px {
  height: 15rem !important;
}
.fix-avtar-1-5 {
  @include fix-avtar(1.5rem, 1.5rem);
}
.fix-avtar-1-8 {
  @include fix-avtar(1.8rem, 1.8rem);
}
.fix-avtar-2 {
  @include fix-avtar(2rem, 2rem);
}
.fix-avtar-2-5 {
  @include fix-avtar(2.5rem, 2.5rem);
}
.fix-avtar-3 {
  @include fix-avtar(3rem, 3rem);
}
.fix-avtar-4 {
  @include fix-avtar(4rem, 4rem);
}
.fix-avtar-7 {
  @include fix-avtar(7rem, 7rem);
}
.fix-avtar-9 {
  @include fix-avtar(9rem, 9rem);
}
.fix-avtar-10 {
  @include fix-avtar(10rem, 10rem);
}
.avtar-2-5 {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
}
.avtar-3 {
  max-width: 3rem;
  max-height: 3rem;
  object-fit: cover;
}
.avtar-7 {
  max-width: 7rem;
  max-height: 7rem;
  object-fit: cover;
}
.avtar-squar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.circle {
  @include border-radius(50%);
  object-fit: cover;
}
.rounded {
  border-radius: 1em;
  border-color: var(--white-color);
  background-color: var(--white-color);
}
.object-fit-contain {
  object-fit: contain !important;
}
.object-pos-top {
  object-position: top !important;
}
.br-0 {
  border-radius: 0 !important;
}
.br-5 {
  border-radius: 5px !important;
}
.br-10 {
  border-radius: 0.75rem !important;
}
.br-15 {
  border-radius: 0.9rem !important;
}
.center {
  @include display-grid();
  @include align-items(center);
  min-height: 100%;
  @include align-content(center);
}
.overflow-visible {
  overflow: visible;
}
.overflow-auto {
  overflow: auto;
}
.z-index-100 {
  z-index: 100;
}
.pointer {
  cursor: pointer;
}
.cursor-auto {
  cursor: auto;
}
.icon-rotate-90 {
  @include transform(rotate(90.03deg));
}
.icon-rotate-45 {
  @include transform(rotate(45.03deg));
}
.lh-1 {
  line-height: 1;
}
.lh-1-15 {
  line-height: 1.15;
}
.lh-1-2 {
  line-height: 1.2;
}
.lh-1-5 {
  line-height: 1.5;
}
.lh-1-7 {
  line-height: 1.7;
}

/* Dropdown CSS */
.dropdown-menu {
  padding: 0.1rem 0;
  margin-right: 0.8rem;
  border-color: $medium-gray-color;
  .dropdown-item {
    font-size: 1rem;
    padding: 0.6rem 1rem 0.3rem 1rem;
    &:active,
    &:hover,
    &:focus {
      background-color: var(--light-gray-color);
      color: $theme-color;
    }
    .btn-dropdown-right-icon {
      position: absolute;
      top: 0.1875rem;
      right: 0.625rem;
      height: 100%;
      padding: 5px;
    }
  }
  .dropdown-divider {
    margin: 0.1rem 0.7rem;
    border-top: 1px solid $medium-gray-color;
  }
}
.dropdown-img {
  .dropdown-toggle {
    outline: none;
    @include box-shadow(none);
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    .dropdown-divider {
      margin: 0;
    }
  }
}
/* Dropdown CSS Ends*/

/* Popover */
.popover-body ul li {
  padding: 0.1875rem 0;
  text-align: center;
  border-bottom: 1px solid $medium-gray-color;
  &:last-child {
    border-bottom: 0 solid transparent;
  }
}
/* Popover Ends*/

.text-danger {
  color: $danger-color;
}
.text-gray {
  color: $medium-gray-color;
}
.text-underline {
  text-decoration: underline !important;
}
.disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  @include opacity(0.7);
}
.opacity-0-4 {
  @include opacity(0.4);
}
/* :Loader CSS*/
/* component loader*/
.loading {
  position: absolute;
  background: url("../images/loader.gif") center center no-repeat $theme-color;
  @include opacity(0.8);
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.view-profileLoader {
  position: absolute;
  background: url("../images/view-profileLoader.gif") center center no-repeat
    var(--white);
  @include opacity(0.8);
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.view-profileLoader1 {
  position: absolute;
  background: url("../images/view-profileLoader.gif") center center no-repeat;
  @include opacity(0.8);
  // z-index: 1000;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
/*full screen loader*/
.loader {
  position: fixed;
  background: url("../images/loader.gif") center center no-repeat $theme-color;
  @include opacity(0.8);
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
/* :Loader CSS Ends*/

/* Accessibility CSS */
// a:focus,
// button:focus {
//   outline: 0.3125rem auto -webkit-focus-ring-color;
//   outline: 1px dotted $theme-color;
// }
// a:-moz-focusring,
// button:-moz-focusring {
//   outline: 1px dotted $theme-color;
// }
// th:focus {
//   outline: 0.3125rem auto -webkit-focus-ring-color;
//   outline: 1px dotted $theme-color;
//   z-index: 100;
// }
/* Accessibility CSS */

.flaticon-eye:before {
  padding-top: 5px;
}
.tooltip {
  z-index: 100000;
}
/* Common CSS Ends */

.lt-custom {
  display: grid;
  margin: 0;
  padding: 0;
  min-height: 100%;
  grid-auto-rows: -webkit-min-content auto -webkit-min-content;
  grid-auto-rows: min-content auto min-content;
  grid-template-areas:
    "lt-header"
    "lt-content"
    "lt-footer";
  .lt-header {
    grid-area: lt-header;
  }
  .lt-content {
    width: 100%;
    overflow-x: auto;
    grid-area: lt-content;
  }
  .lt-footer {
    grid-area: lt-footer;
  }
}

/* Form Elemtnt CSS */
.icon-inside {
  @include transform(rotateZ(0.03deg));
}
.form-group.with-icon {
  display: block;
  padding-left: 1.875rem;
  .icon {
    font-size: 1.5rem;
    position: absolute;
    float: left;
    top: 0;
    left: 0;
    color: $medium-gray-color;
    @include transform(rotateZ(0.03deg));
  }
  .error {
    color: $medium-gray-color;
  }
  .control-label {
    left: unset !important;
    width: calc(100% - 1.875rem);
  }
  .right-icon ~ .control-label {
    width: calc(100% - 1.875rem - 1.875rem);
  }
}
.form-group {
  .right-icon {
    position: absolute;
    padding: 0 5px;
    float: left;
    top: 0;
    right: 0;
    color: $medium-gray-color;
    font-size: 1.375rem;
    border: none;
    background: none;
    z-index: 10;
    @include transform(rotateZ(0.03deg));
    &:hover,
    &:focus {
      color: $theme-color;
      cursor: pointer;
    }
    & ~ input {
      padding-right: 2.5rem;
    }
    i {
      font-size: 1.375rem;
    }
    & ~ .control-label {
      width: calc(100% - 2.5rem);
    }
  }
  .left-icon {
    position: absolute;
    float: left;
    top: 0;
    left: 0;
    color: $medium-gray-color;
    @include transform(rotateZ(0.03deg));
    &:hover,
    &:focus {
      color: $theme-color;
      cursor: pointer;
    }
    & ~ input {
      padding-left: 2.5rem;
    }
    & ~ .control-label {
      width: calc(100% - 2.5rem);
    }
  }
}
.form-radio,
.form-group {
  position: relative;
  margin-top: 1.3rem;
  margin-bottom: 0;
  z-index: 0;
  min-height: 40px;
}

.form-inline > .form-group,
.form-inline > .btn {
  display: inline-block;
  margin-bottom: 0;
}

.form-help {
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  color: $medium-gray-color;
  font-size: 0.8rem;
}
.checkbox .form-help,
.form-radio .form-help,
.form-group .form-help {
  position: absolute;
  width: 100%;
}
.checkbox .form-help {
  position: relative;
  margin-bottom: 1rem;
}
.form-radio .form-help {
  padding-top: 0.25rem;
  margin-top: -1rem;
}
.form-group input {
  height: 1.9rem;
}
.form-group textarea {
  resize: none;
  &:hover,
  &:focus {
    outline: none;
    @include box-shadow(none);
  }
}
.form-group select {
  width: 100%;
  font-size: 1rem;
  padding: 0.45rem 0.125rem 0.45rem;
  background: none;
  border: none;
  line-height: 1.6;
  @include box-shadow(none);
}
.form-group.select {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    top: 40%;
    right: 0.625rem;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: $theme-color transparent transparent transparent;
    pointer-events: none;
  }

  &:before {
    content: "";
    position: absolute;
    width: 2.2rem;
    height: 100%;
    padding: 0 5px;
    top: 0;
    right: 0;
    pointer-events: none;
  }

  select {
    width: 100%;
    font-size: 1rem;
    padding: 0.45rem 0.125rem 0.45rem;
    background: none;
    border: none;
    line-height: 1.6;
    @include box-shadow(none);
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;

    // Disable ugly ass outline on firefox
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
    // Disable default arrow on IE 11+
    &::-ms-expand {
      display: none;
    }
  }
}
.form-radio .radio input[type="radio"] {
  height: unset;
  line-height: 1;
}
.form-group .control-label {
  position: absolute;
  top: 0.2rem;
  left: 0;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  color: $medium-gray-color;
  background-color: inherit;
  font-size: 1rem;
  font-weight: normal;
  @include transition(all 0.28s ease);
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.5625rem;
}
.control-label span {
  pointer-events: auto;
  font-size: inherit;
  i {
    font-size: inherit;
  }
}
.form-group .bar {
  position: relative;
  border-bottom: 1px solid $medium-gray-color;
  display: block;
}
.form-group .bar::before {
  content: "";
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -1px;
  position: absolute;
  background: $gray-color;
  @include transition(left 0.28s ease, width 0.28s ease);
  z-index: 2;
}
.form-group input,
.form-group textarea {
  display: block;
  background: none;
  padding: 1.125rem 0.125rem 1.0625rem;
  font-size: 1rem;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  @include transition(all 0.28s ease);
  @include box-shadow(none);
}
.form-group input[type="file"] {
  line-height: 1;
}
.form-group input[type="file"] ~ .bar {
  display: none;
}
.form-group select,
.form-group input:focus,
.form-group input:valid,
.form-group input.form-file,
.form-group input.has-value,
.form-group .has-value,
.form-group textarea:focus,
.form-group textarea:valid,
.form-group textarea.form-file,
.form-group textarea.has-value,
.has-value-label {
  color: $theme-color;
}
.no-value-label {
  font-size: 0.8rem !important;
  top: -1rem !important;
  color: $medium-gray-color !important;
  background-color: transparent !important;
}
.form-group select.no-value ~ .control-label,
.form-group input:valid.no-value ~ .control-label,
.form-group textarea:valid.no-value ~ .control-label {
  font-size: 1rem;
  top: 0.2rem;
  color: $medium-gray-color;
  background-color: inherit;
  height: 1.5625rem;
}
.form-group select ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group input:focus.no-value ~ .control-label,
.form-group input:valid ~ .control-label,
.form-group input.form-file ~ .control-label,
.form-group input.has-value ~ .control-label,
.form-group .has-value ~ .control-label,
.form-group textarea:focus ~ .control-label,
.form-group textarea:valid ~ .control-label,
.form-group textarea.form-file ~ .control-label,
.form-group textarea.has-value ~ .control-label {
  font-size: 0.8rem;
  color: $medium-gray-color;
  top: -1rem;
  height: 1.25rem;
}
.has-value-label {
  font-size: 0.8rem !important;
  top: -1rem !important;
  color: $gray-color !important;
  background-color: transparent !important;
}
.form-group select:focus,
.form-group input:focus,
.form-group textarea:focus {
  outline: none;
}
.form-group select:focus ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group textarea:focus ~ .control-label,
.form-group select:focus ~ .icon,
.form-group input:focus ~ .icon,
.form-group textarea:focus ~ .icon {
  color: $gray-color;
}
.form-group select:focus ~ .bar::before,
.form-group input:focus ~ .bar::before,
.form-group textarea:focus ~ .bar::before,
.form-group .bar_focus::before {
  width: 100%;
  left: 0;
}

.checkbox label,
.form-radio label {
  position: relative;
  cursor: pointer;
  padding-left: 1.4rem;
  padding-right: 0.3rem;
  margin-bottom: 0;
  text-align: left;
  display: inline-block;
}
.checkbox input,
.form-radio input {
  width: auto;
  @include opacity(0.00000001);
  position: absolute;
  left: 0;
}

.radio {
  margin-bottom: 0.5rem;
}
.radio .helper {
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  @include user-select(none);
  color: $medium-gray-color;
}
.radio .helper::before,
.radio .helper::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  // margin: 0.25rem 0.25rem 0.25rem 0.6rem;
  margin: 0.25rem 0.25rem 0.25rem 0.25rem;
  width: 1rem;
  height: 1rem;
  @include transition(-webkit-transform 0.28s ease);
  @include transition(-moz-transform 0.28s ease);
  @include transition(-ms-transform 0.28s ease);
  @include transition(-o-transform 0.28s ease);
  @include transition(transform 0.28s ease);
  @include border-radius(50%);
  border: 0.125rem solid currentColor;
}
.radio .helper::after {
  @include transform(scale(0));
  background-color: $gray-color;
  border-color: $gray-color;
}
.radio input[type="radio"]:focus ~ .helper,
.radio label:hover .helper,
.radio label:focus .helper {
  color: $gray-color;
}
.radio input:checked ~ .helper::after {
  @include transform(scale(0.5));
}
.radio input:checked ~ .helper::before {
  color: $gray-color;
}
.radio-error-show {
  display: none;
}
.radio-has-error ~ .radio-error-show {
  display: block;
}

.checkbox {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
.checkbox .helper {
  color: $medium-gray-color;
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  z-index: 0;
  border: 0.125rem solid currentColor;
  @include border-radius(1px);
  @include transition(border-color 0.28s ease);
}
.checkbox .helper::before,
.checkbox .helper::after {
  position: absolute;
  height: 0;
  width: 0.2rem;
  background-color: $gray-color;
  display: block;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  @include border-radius(0.25rem);
  content: "";
  @include transition(opacity 0.28s ease, height 0s linear 0.28s);
  @include opacity(0);
}
.checkbox .helper::before {
  top: 0.65rem;
  left: 0.38rem;
  @include transform(rotate(-135deg));
  @include box-shadow(0 0 0 1px $white-color);
}
.checkbox .helper::after {
  top: 0.3rem;
  left: 0;
  @include transform(rotate(-45deg));
}
.checkbox input[type="checkbox"]:focus ~ .helper,
.checkbox label:hover .helper,
.checkbox label:focus .helper {
  color: $gray-color;
}
.checkbox input:checked ~ .helper {
  color: $gray-color;
}
.checkbox input:checked ~ .helper::after,
.checkbox input:checked ~ .helper::before {
  @include opacity(1);
  @include transition(height 0.28s ease);
}
.checkbox input:checked ~ .helper::after {
  height: 0.5rem;
}
.checkbox input:checked ~ .helper::before {
  height: 1.2rem;
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: 0.5rem;
}

.has-error .legend.legend,
.has-error.form-group .control-label.control-label,
.has-error.form-group .form-help,
.has-error.form-group .helper,
.has-error.checkbox .form-help,
.has-error.checkbox .helper,
.has-error.radio .form-help,
.has-error.radio .helper,
.has-error.form-radio .form-help,
.has-error.form-radio .helper,
.has-error .icon,
.has-error .error,
.has-error {
  color: $danger-color;
}
.has-error .bar::before {
  background: $danger-color;
  left: 0;
  width: 100%;
}
.btn {
  border-radius: 0.5em;
  font-size: 0.875rem;
  i {
    font-size: 1.125rem;
  }
}
.btn-no-change {
  padding: 0;
  margin: 0;
  color: inherit;
  background: inherit;
  &:hover,
  &:focus {
    background-color: inherit;
    color: inherit;
    @include box-shadow(none);
  }
}
.btn-black {
  padding: 0.35em 1em;
  font-size: 0.85rem;
  border-radius: 0.5em;
  background-color: var(--black-color);
  color: var(--white-color);
  border: 1px solid var(--black-color);
  min-width: 25%;
  &:hover {
    background-color: var(--white-color);
    color: var(--black-color);
    border: 1px solid var(--black-color);
    box-shadow: none;
  }
  &:focus {
    box-shadow: 0 0 0.85rem var(--black-color);
  }
}

.btn-outline-black {
  padding: 0.35em 1em;
  font-size: 0.85rem;
  background-color: var(--white-color);
  color: var(--black-color);
  border: 1px solid var(--black-color);
  border-radius: 0.5em;
  min-width: 25%;
  &:hover {
    background-color: var(--black-color);
    color: var(--white-color);
    border: 1px solid var(--black-color);
    box-shadow: none;
  }
  &:focus {
    box-shadow: 0 0 0.85rem var(--black-color);
  }
  i {
    font-size: 1rem;
  }
}
.btn-border {
  border: 1px solid transparent;
  border-radius: 0.5em;
  padding: 0.5em;
  &:hover,
  &:focus,
  &:focus-within {
    border: 1px solid $medium-gray-color;
    box-shadow: none;
    outline: 0;
    text-decoration: none;
  }
}
.btn-danger {
  padding: 0.35em 1em;
  font-size: 0.85rem;
  border-radius: 0.5em;
  background-color: var(--danger-color);
  color: var(--white-color);
  border: 1px solid var(--danger-color);
  min-width: 25%;
  &:hover {
    background-color: var(--white-color);
    color: var(--danger-color);
    border: 1px solid var(--danger-color);
    box-shadow: none;
  }
  &:focus {
    background-color: var(--danger-color);
    color: var(--white-color);
    box-shadow: 0 0 0.85rem var(--danger-color);
    i {
      font-weight: 900;
    }
  }
}
.btn-danger-outline {
  padding: 0.35em 1em;
  font-size: 0.85rem;
  background-color: var(--white-color);
  color: var(--danger-color);
  border: 1px solid var(--danger-color);
  border-radius: 0.5em;
  min-width: 25%;
  &:hover {
    background-color: var(--danger-color);
    color: var(--white-color);
    border: 1px solid var(--danger-color);
    box-shadow: none;
  }
  &:focus {
    box-shadow: 0 0 0.85rem var(--danger-color);
  }
  i {
    font-size: 1rem;
  }
}
.btn-img-no-effect {
  margin: 0;
  padding: 1px;
  background-color: transparent;
  border: 1px solid $shadow-color;
  &:hover,
  &:focus {
    outline: 0;
    @include box-shadow(none);
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.btn-img {
  margin: 0;
  padding: 1px;
  background-color: transparent;
  border: 1px solid $shadow-color;
  &:hover,
  &:focus {
    outline: 1px solid $theme-color;
    @include box-shadow(none);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.btn-img-rounded {
  margin: 0;
  padding: 2px;
  border: 1px solid var(--light-gray-color);
  border-radius: 0.5rem;
  display: block;
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: none;
    box-shadow: none;
    border-radius: 0.5rem;
  }
  img {
    width: 100%;
    height: 100%;
    background-color: var(--white-color);
    border-radius: 0.5rem;
    object-fit: cover;
  }
}
.btn-img-circle {
  margin: 0;
  padding: 2px;
  border: 0;
  background-color: $shadow-color;
  border-radius: 50% !important;
  display: block;
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: none;
    background-color: $medium-gray-color;
    @include box-shadow(none);
    @include border-radius(50%);
  }
  img {
    width: 100%;
    height: 100%;
    padding: 1px;
    background-color: $white-color;
    @extend .circle;
  }
}
.no-border-hover:hover,
.no-border-hover:focus {
  border: 1px solid transparent;
  outline: none;
}
.btn-link-theme {
  color: $theme-color;
  font-weight: 700;
  vertical-align: unset;
  &:hover,
  &:focus {
    color: $theme-color;
    font-weight: 700;
    @include box-shadow(none);
  }
}
.btn-tbl-link-theme {
  text-decoration: none;
  color: $theme-color;
  font-weight: normal;
  vertical-align: unset;
  padding: 0;
  &:hover,
  &:focus {
    color: $theme-color;
    font-weight: 900;
    text-decoration: none;
    @include box-shadow(none);
  }
}
.btn-tbl-link-danger {
  text-decoration: none;
  color: $danger-color;
  font-weight: normal;
  vertical-align: unset;
  padding: 0;
  &:hover,
  &:focus {
    color: $danger-color;
    font-weight: 900;
    text-decoration: none;
    @include box-shadow(none);
  }
}
.btn-underline {
  text-decoration: none;
  color: $theme-color;
  font-weight: normal;
  vertical-align: unset;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $theme-color;
    font-weight: normal;
    @include box-shadow(none);
  }
}

.btn-underline-hover-bold {
  text-decoration: underline;
  color: $theme-color;
  font-weight: normal;
  vertical-align: unset;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $theme-color;
    font-weight: bold;
    @include box-shadow(none);
  }
}

.btn-card {
  border: 1px solid transparent;
  @include box-shadow(0 0 0.05rem $dark-shadow-color);
  -webkit-transition: -webkit-transform 0.4s linear;
  transition: -webkit-transform 0.4s linear;
  transition: transform 0.4s linear, -webkit-transform 0.4s linear;
  &:hover {
    // -webkit-transform: scale(1.1);
    // transform: scale(1.1);
    -webkit-transition: -webkit-transform 0.4s linear;
    transition: -webkit-transform 0.4s linear;
    transition: transform 0.4s linear, -webkit-transform 0.4s linear;
    @include box-shadow(0 0 1rem $dark-shadow-color !important);
  }
  &:focus {
    box-shadow: 0 0 1rem var(--gray-color);
  }
}

.btn-dropdown-theme:after {
  content: none;
}
.linkify-html {
  a,
  .linkify-mention {
    color: rgb(27, 149, 224);
    &:hover {
      //color: var(--primary-color);
      color: rgb(27, 149, 224);
    }
  }
}
input[type="password"] {
  letter-spacing: 0.1875rem;
  -webkit-text-security: "֍";
}
.form-group input[type="number"]::-webkit-inner-spin-button,
.form-group input[type="number"]::-webkit-outer-spin-button,
.form-group input[type="number"],
.form-group input[type="number"]:focus {
  @include appearance(none);
  -moz-appearance: textfield;
  margin: 0;
}

.form-group input[type="number"],
.form-group input[type="number"]:focus {
  padding: 0;
}
ngx-file-picker {
  @include border-radius(50% !important);
}
.file-drop-wrapper {
  max-width: 15rem;
  max-height: 15rem;
  height: 100%;
  background: transparent !important;
  padding-top: 0 !important;
  file-drop {
    height: 100%;
    padding: 0 !important;
  }
  #dropZone {
    @include display-grid();
    height: 100%;
    background: rgba(255, 255, 255, 0.5) !important;
    // opacity: 0.5;
    border: 2px dashed $theme-color !important;
    @include border-radius(0 !important);
  }
  .custom-dropzone {
    margin: auto;
  }
}

input:disabled {
  background-color: $white-color;
  @include opacity(0.7);
}
.custom-file-label {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5rem;
  white-space: nowrap;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: $theme-color;
  box-shadow: none;
}

.flaticon-eye:before {
  padding-top: 0.3em;
}

.square-form {
  .form-group {
    min-height: auto;
    position: relative;
    border-radius: 0.3125em;
    background-color: inherit;
    z-index: auto;
    .form-control {
      border: 1px solid var(--gray-color);
      background-color: var(--white-color);
      border-radius: 0.3125em;
      padding: 0.85em 1.125em;
      line-height: 1.3;
      font-size: 16px;
      color: var(--gray-color);
      height: auto;
      min-height: 3em;
      appearance: none;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--white-color) inset !important;
        -webkit-box-shadow: 0 0 0 30px var(--white-color) inset !important;
      }
      & ~ label {
        z-index: 1;
        border: 0;
        color: var(--gray-color);
        line-height: 1.3;
        position: absolute;
        top: 0;
        left: 0;
        margin: 2px;
        padding: 0.85em 1.125em;
        max-width: calc(100% - 0.25em);
        width: calc(100% - 0.25em);
        height: calc(3em - 0.25em);
        white-space: nowrap;
        background: var(--white-color);
        font-size: 1em;
        border-radius: 0.3125rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
        transition: all 0.28s ease, width 0.5s ease;
        pointer-events: none;
      }
      &.has-value {
        outline: 0;
        box-shadow: none;
        border: 1px solid var(--gray-color);
        color: var(--gray-color);
        & ~ label {
          width: auto;
          height: auto;
          position: absolute;
          top: -0.5em;
          left: 0.6875rem;
          font-size: 0.85em;
          border: 0;
          margin: 0;
          padding: 0 0.5em;
          background: var(--white-color);
          color: var(--gray-color);
          line-height: 1.3em;
          max-width: 90%;
        }
      }
      &:focus-within,
      &:focus {
        outline: 0;
        padding: 0.85em 1.125em;
        box-shadow: none;
        border: 1px solid var(--dark-gray-color);
        color: var(--dark-gray-color);
        & ~ label {
          width: auto;
          height: auto;
          position: absolute;
          top: -0.5em;
          left: 0.6875rem;
          font-size: 0.85em;
          border: 0;
          margin: 0;
          padding: 0 0.5em;
          background: var(--white-color);
          color: var(--dark-gray-color);
          line-height: 1.3em;
          max-width: 90%;
        }
      }
      &:disabled,
      &[readonly],
      &[readonly].has-value ~ label,
      &:disabled.has-value ~ label {
        background-color: #e9ecef;
        opacity: 1;
      }
    }
    select.form-control {
      height: 3em;
      padding: 0.6em 1.125em;
      &:focus-within,
      &:focus {
        padding: 0.65em 1.125em;
      }
    }
    textarea.form-control {
      white-space: pre-wrap;
      appearance: none;
    }
    .right-icon {
      position: absolute;
      padding: 0.375rem;
      float: left;
      top: 2px;
      right: 2px;
      color: var(--gray-color);
      font-size: 1.375rem;
      border: none;
      background: none;
      z-index: 10;
      transform: rotateZ(0.03deg);
      height: auto;
      line-height: 1;
      max-height: calc(3em - 4px);
      background: var(--white-color);
      border-radius: 0.5em;
      &:hover,
      &:focus {
        outline: 0;
        color: var(--dark-gray-color);
      }
      &.font-16 {
        padding: 0 0.375rem;
        height: calc(2.5em - 4px);
        align-items: center;
        display: flex;
        .fi {
          font-size: 1rem;
        }
      }
    }
    .checkbox {
      margin: 0;
      label {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0;
        &::before {
          content: "";
          border: 1px solid var(--gray-color);
          width: 1.25em;
          height: 1.25em;
          border-radius: 3px;
          display: block;
          margin: auto 0.3125rem auto 0;
        }
      }
      input[type="checkbox"] {
        &:checked ~ label::before {
          border: 1px solid var(--dark-gray-color);
          background-color: var(--dark-gray-color);
        }
        &:checked ~ label::after {
          content: "";
          position: absolute;
          width: 0.7em;
          height: 0.3em;
          top: -1px;
          bottom: 0;
          margin: auto;
          border-left: 2px solid var(--white-color);
          border-bottom: 2px solid var(--white-color);
          transform: rotate(-45deg);
          left: 3px;
        }
      }
    }
    .invalid-feedback {
      display: block;
      font-size: 0.8rem;
      color: var(--danger-color);
      padding: 0;
    }
    .form-control.e-control {
      padding: 0;
      .e-input-group {
        margin: 0;
        padding: 0;
        border: 0;
        .e-input {
          padding: 0.85em 1.125em;
          line-height: 1.3;
          font-size: 1rem;
          color: var(--gray-color);
          height: auto;
          min-height: auto;
        }
        &::before,
        &::after {
          content: none;
        }
        &.e-input-focus {
          border: 0 !important;
        }
        .e-clear-icon {
          padding-bottom: 0;
        }
      }
      .e-date-icon {
        margin: 0;
        padding: 0.5em;
        &::before {
          content: $font-Flaticon-calendar;
          font-family: "Flaticon";
          font-weight: normal;
          font-style: normal;
          font-size: 1.2em;
          @include transform(rotateZ(0.03deg));
        }
      }
      .e-multiselect {
        .e-chips-collection {
          padding: 0.85em 0 0 1.125em;
          line-height: 1.3;
          font-size: 1em;
          float: left;
        }
        .e-delim-view {
          padding: 0.85em 1.125em;
          line-height: 1.3;
          font-size: 1em;
          float: left;
          .e-remain {
            padding-left: 0.3125rem;
            line-height: 1.3;
          }
        }
        .e-dropdownbase {
          padding: 0.85em 1.125em;
          line-height: 1.3;
          box-sizing: border-box;
          font-size: 1em;
          height: auto;
          min-height: auto;
        }
      }
      &.e-dropdownlist {
        .e-input-group {
          height: 3em;
        }
        .e-search-icon {
          margin: 0;
        }
      }
      &.e-datepicker {
        height: 3em;
        .e-input-group {
          height: 100%;
        }
      }
    }
  }
  .invalid-feedback {
    font-size: 0.8rem;
    color: var(--danger-color);
    padding: 0;
  }
}
/* Form Elemtnt CSS Ends */

/* Header CSS */
header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: $white-color;
  @include box-shadow(0 0 0.9375rem $shadow-color);
  .title {
    font-size: 1.875rem;
    font-variant: normal;
    font-weight: 100;
    color: $theme-color;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $theme-color;
    }
  }
  .dropdown-menu {
    min-width: 200px;
    .dropdown-item {
      padding: 0.6rem 1.5rem 0.3rem 1.5rem;
      .fi {
        margin-right: 5px;
      }
    }
  }
  .navigation-menu .close {
    display: none;
  }
  .navbar-collapse,
  .navbar {
    background: inherit;
  }
  .navbar-toggler {
    margin: 0.85rem 0;
  }
  .nav-item {
    padding: 0.625rem 0;
    align-self: center;
    margin: 0 1.25rem;
  }
  .nav-link {
    padding: 0;
    line-height: 1.25;
  }
}
.category-header {
  .menu {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
}
.profile-header {
  border-bottom: 1px solid $shadow-color;
  @include box-shadow(none);
  .search-sm {
    display: none;
  }
}
/* Header CSS Ends */

/* Footer CSS */
.admin-footer {
  position: fixed;
  bottom: 0;
  height: var(--footer-height);
  border-top: 1px solid $shadow-color;
  background-color: $white-color;
  z-index: 100;
  .title {
    font-size: 1.875rem;
    font-variant: normal;
    font-weight: 100;
    color: $theme-color;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $theme-color;
    }
  }
}
/* Footer CSS Ends */

/* Content CSS */
.content {
  --background-color: var(--white-color);
  background-color: var(--white-color);
  height: 100%;
  padding: 1rem;
  .div-center {
    padding-bottom: 0 !important;
  }
}

#termAndCondition {
  .modal-header {
    background: $white-color;
    color: $theme-color;
  }
}
.text-link {
  margin-left: 0.9375rem;
  color: $gray-color;
  .btn-link-theme {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 0.75rem !important;
    font-weight: 900;
  }
}

.select-category-icon {
  height: 100px;
  width: 100px;
  padding: 15px;
  @include box-shadow(5px 5px $medium-gray-color);
  border: 1px solid $black-color;
}
/* Content CSS Ends */

/* Admin CSS */
.admin-content,
.profile-content,
.homepage-content {
  height: 100%;
}
/* Admin CSS Ends */

/* Profile CSS */
.profile-content {
  padding: 2.2rem 0;
  --background-color: var(--light-gray-color);
  background-color: var(--light-gray-color);
}
.profile-container {
  background-color: var(--white-color);
  border-radius: 1rem;
}

.profile-list {
  .claim-list {
    border-bottom: 1px solid $medium-gray-color;
    .btn-list {
      border-radius: 0;
      &:hover,
      &:focus {
        background-color: var(--light-gray-color);
        outline: none;
        @include box-shadow(none);
      }
    }
    &.no-last-border:last-child {
      border-bottom: none;
    }
  }
}
.profile-image {
  overflow: hidden;
  position: relative;
}
.profile-upload {
  @include opacity(0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  .btn {
    border: 0;
    width: 100%;
    i::before {
      padding: 0;
    }
  }
}
.profile-image:hover .profile-upload,
.profile-image:focus .profile-upload,
.profile-image:focus-within .profile-upload {
  @include opacity(1);
  @include transition(opacity 0.5s ease-out);
}

#viewProfileModal {
  background-color: rgba(0, 0, 0, 0.6);
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: $white-color;
  }
}

.post-review-model {
  .review-area {
    position: relative;
    border: 1px solid var(--light-gray-color);
    margin-top: 0;
    .form-control {
      font-size: 0.85rem;
      line-height: 1.5;
    }
  }
}

.review {
  text-align: left;
  border: none;
  border-bottom: 1px solid $shadow-color;
  border-top: 1px solid $shadow-color;
  background-color: transparent;
  padding: 0.8rem 0;
  margin: 0;
  .btn-response {
    @include box-shadow(none);
    padding: 0.375rem 0 0 0;
    font-weight: normal;
    font-size: 1.5rem;
    color: $theme-color;
    line-height: 1;
    text-decoration: none;
    span {
      vertical-align: text-top;
    }
    i {
      vertical-align: text-bottom;
      &::before {
        font-size: 1.625rem;
      }
    }
    &.add i {
      vertical-align: text-top;
      &::before {
        font-size: 1.5rem;
      }
    }
    &:hover {
      font-weight: 700;
    }
  }
  .response-list {
    // display: none;
    // height: 100%;
    @include transition(all 0.5s linear);
  }
  .response {
    word-break: break-word;
    background-color: $shadow-color;
    border-radius: 1rem;
    @include transition(all 0.5s linear);
    .post-gallery .img {
      border-radius: 1rem;
      img,
      .view-btn,
      .view-more:hover {
        border-radius: 1rem;
        .view-btn:first-child {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        .view-btn:last-child {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }
  .add-response {
    .add-response-input {
      width: 100%;
      height: 100%;
      background-color: $shadow-color;
      border: 0;
      border-radius: 1rem;
      &:focus {
        outline: none;
      }
    }
  }
  &:hover,
  &:focus,
  &:focus-within {
    border-bottom: 1px solid var(--light-gray-color);
    border-top: 1px solid var(--light-gray-color);
    background-color: var(--white-color);
    outline: none;
    .response {
      background-color: $shadow-color;
    }
    .add-response-input {
      background-color: $shadow-color;
    }
  }
}
.profile-tranding-review {
  word-break: break-all;
  background-color: $light-shadow-color;
  .tranding-heading {
    border-bottom: 1px solid $shadow-color;
    padding: 1rem;
  }
  .tranding-review {
    padding: 0;
    border-bottom: 1px solid $shadow-color;
    .review-content {
      word-break: break-all;
    }
  }
  .tranding-profile {
    border: 1px solid $shadow-color;
  }
  .similar-profile {
    width: 100%;
    display: grid;
    padding: 1rem;
    row-gap: 1rem;
    grid-template-columns: repeat(auto-fit, 100px);
    grid-template-rows: repeat(auto-fit, auto);

    // overflow: auto;
    // white-space: nowrap;
    .s-profile {
      width: 100px;
      display: inline-block;
      text-align: center;
      vertical-align: top;
      img {
        width: 4rem;
        height: 4rem;
      }
      p {
        white-space: normal;
      }
    }
  }
}
/* Profile CSS Ends */

/* Homepage CSS */
.homepage-search {
  .ng-autocomplete ng-autocomplete .autocomplete-container {
    border-right: 0;
  }
  .search-btn {
    font-size: 1rem;
    padding: 0.1rem 0.8rem;
    min-width: auto;
    border: 1px solid var(--table-th-color);
    border-left: 0;
    color: $theme-color;
    background-color: $white-color;
    font-weight: 500;
    &:hover,
    &:focus {
      font-weight: 600;
    }
  }
  .search-btn[disabled],
  .search-btn:disabled {
    opacity: 1;
    &:hover,
    &:focus {
      font-weight: 500;
    }
  }
}
.homepage-content {
  @include box-shadow(none);
  .homepage-logo {
    border: 1px solid transparent;
    max-width: 13.5rem;
    @include aspect-ratio(1200, 400);
  }
  .tranding-review {
    .review-text {
      position: relative;
      color: $medium-gray-color;
      .text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1rem; /* fallback */
        // -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical; /* fallback */
        &.text-ellipsis-1 {
          -webkit-line-clamp: 1; /* number of lines to show */
          max-height: 1rem; /* fallback */
        }
        &.text-ellipsis-2 {
          -webkit-line-clamp: 2; /* number of lines to show */
          max-height: 2rem; /* fallback */
        }
        &.text-ellipsis-3 {
          -webkit-line-clamp: 3; /* number of lines to show */
          max-height: 3rem; /* fallback */
        }
        &.text-ellipsis-4 {
          -webkit-line-clamp: 4; /* number of lines to show */
          max-height: 4rem; /* fallback */
        }
      }

      .read-more {
        float: right;
        background-color: $white-color;
        font-size: 0.75rem;
        color: $theme-color;
        padding: 0;
        z-index: 100;
        // position: absolute;
        // right:0;
        // bottom:0;
      }
    }
  }
}
/* Homepage CSS Ends */

/* Table CSS */
.table-responsive .table {
  border: 1px solid var(--table-th-color) !important;
  font-size: 0.875rem;
  border-radius: 1em;
  overflow: hidden;
  tr {
    border-bottom: 1px solid var(--table-th-color);
    th {
      background-color: var(--table-th-color);
      border: 0;
      border-bottom: 1px solid var(--table-th-color);
      color: $theme-color;
      text-align: center;
      vertical-align: middle;
      padding: 1em;
    }
    td {
      border: 0;
      border-bottom: 1px solid var(--table-th-color);
      text-align: center;
      vertical-align: middle;
      padding: 0.4375rem;
    }
  }
}
// div.dataTables_wrapper div.dataTables_filter {
//   display: none !important;
//   visibility: hidden !important;
// }
/* Table CSS Ends */

/* Modal CSS */
.modal-dialog-custom {
  border-radius: 0;
  height: calc(100% - 3.5rem);
  .modal-content {
    border-radius: 1em;
    overflow: hidden;
    .modal-header {
      border-radius: 0.9em 0.9em 0 0;
      padding: 0.5em 1em;
    }
    .modal-body {
      border-radius: 0 0 0.9em 0.9em;
    }
    .close {
      position: absolute;
      padding: 0;
      right: 1rem;
      margin: 0;
      opacity: 1;
      color: var(--black-color);
      &.font-32 {
        top: 0;
      }
    }
  }
}
.modal-danger {
  .modal-content {
    .modal-header {
      background-color: $danger-color;
      color: $white-color;
      .modal-title {
        width: 100%;
      }
      .close {
        color: #fff;
      }
    }
  }
}
.modal-theme {
  .modal-content {
    .modal-header {
      // background-color: $theme-color;
      // color: $white-color;
      // background-color: var(--light-gray-color);
      .modal-title {
        width: 100%;
      }
      // .close {
      //   color: #fff;
      // }
    }
  }
}
.view-profile-modal {
  z-index: 1080 !important;
  background-color: rgba(0, 0, 0, 0.85);
}
.modal-img-viewer {
  margin: 1.5rem auto !important;
  width: calc(100% - 3.5rem);
  height: calc(100% - 5rem);
  min-height: calc(100% - 3rem);
  .modal-content,
  .modal-body {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    .img-view {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      border-radius: 1rem;
    }
  }
}
.modal-file-upload {
  .file-upload {
    .custom-file-input {
      &:focus ~ .custom-file-label,
      &:hover ~ .custom-file-label {
        border-color: $gray-color;
      }
    }
    .custom-file-label {
      outline: none;
      @include box-shadow(none);
      border-radius: 0;
    }
  }
}

.slide-right {
  z-index: 1030 !important;
  .modal-dialog {
    width: 47% !important;
    transform: translateX(-55%) !important;
  }
}
/* Modal CSS Ends */

/* Alert CSS */
.alert {
  border-radius: 0.3125rem;
  padding: 0.75rem 2rem 0.75rem 1.25rem;
  .close {
    position: absolute;
    top: 0;
    height: 100%;
    right: 1rem;
    font-size: 1.2rem;
  }
}
.alert-danger {
  border-color: $danger-color;
  color: $danger-color;
  background: lighten($danger-color, 42%);
  .close {
    color: $danger-color;
    &:hover,
    &:focus {
      color: darken($danger-color, 10%);
    }
  }
}

.alert-success {
  border-color: $success-color;
  color: $success-color;
  background: lighten($success-color, 65%);
  .close {
    color: $success-color;
    &:hover,
    &:focus {
      color: darken($success-color, 10%);
    }
  }
}

.alert-warning {
  border-color: $warning-color;
  color: $warning-color;
  background: lighten($warning-color, 45%);
  .close {
    color: $warning-color;
    &:hover,
    &:focus {
      color: darken($warning-color, 10%);
    }
  }
}

.alert-primary {
  border-color: $primary-color;
  color: $primary-color;
  background: lighten($primary-color, 60%);
  .close {
    color: $primary-color;
    &:hover,
    &:focus {
      color: darken($primary-color, 10%);
    }
  }
}

.alert-theme {
  border-color: $theme-color;
  color: $theme-color;
  background: lighten($theme-color, 85%);
  .close {
    color: $theme-color;
    &:hover,
    &:focus {
      color: darken($theme-color, 10%);
    }
  }
}
/* Alert CSS Ends */

/* whiteboard CSS */
.whiteboard {
  border: 0;
  border-radius: 0;
  background-color: $white-color;
  min-height: 100%;
  padding: 1.5rem;
}
/* whiteboard CSS Ends */

/* Accordion  CSS */
.accordion {
  .card {
    --border: 1px solid var(--light-gray-color);
    border-radius: 0.8em !important;
    overflow: hidden;
    margin: 0.8rem 0;
    border: var(--border) !important;
    .card-header {
      border-radius: 0;
      padding: 0;
      background-color: var(--light-gray-color);
      border: 0;
      .btn-link {
        width: 100%;
        text-align: left;
        text-decoration: none;
        color: $theme-color;
        &:hover,
        &:focus {
          border-radius: 0;
          box-shadow: none;
        }
        .right-icon {
          @include transform(rotate(180.03deg));
          @include transition(transform 0.5s ease);
        }
        &.collapsed .right-icon {
          @include transform(rotate(0.03deg) !important);
        }
      }
    }
  }
}
/* Accordion  CSS Ends */

/* Tabulation CSS */
.theme-tab {
  .nav-link {
    border-radius: 0;
    border: 0;
    color: $medium-gray-color;
    background: $white-color;
    border-top: 2px solid $shadow-color;
    &:hover,
    &:focus {
      color: $medium-gray-color;
      background: $white-color;
      border-top: 2px solid $medium-gray-color;
      outline: none;
    }
    &.active {
      color: $theme-color;
      background: $white-color;
      border-top: 2px solid $theme-color;
      outline: none;
    }
  }
}
/* Tabulation CSS Ends */

/* Gallery CSS */
.post-gallery {
  $row-height: 8rem;
  display: grid;
  grid-gap: 2px;
  grid-auto-flow: dense;
  grid-auto-rows: $row-height;
  @include transition(all 0.28s ease);
  .img {
    position: relative;
    outline: none;
    border: 1px solid $shadow-color;
    border-radius: 1rem;
    @include transition(all 0.28s ease);
    &:hover,
    &:focus {
      outline: 0;
      @include box-shadow(0 0 10px rgba(98, 98, 98, 0.3));
    }
    img,
    video {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      @include transition(all 0.28s ease);
      border-radius: 1rem;
      background-color: var(--black-color);
      &:hover,
      &:focus {
        outline: 0;
        @include box-shadow(0 0 10px rgba(98, 98, 98, 0.3));
      }
    }
    .view-btn {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      @include opacity(0);
      @include transition(all 0.28s ease);
      i,
      h1,
      h2 {
        font-size: 1em;
        color: $white-color;
        @include opacity(0);
        @include transition(all 0.28s ease);
      }
    }
    &:hover .view-btn,
    &:focus .view-btn {
      @include opacity(0);
      @include transition(all 0.28s ease);
      border-radius: 1rem;
    }
    &:hover .view-btn.delete,
    &:focus .view-btn.delete {
      background-color: $theme-color;
      border-radius: 1rem;
      @include opacity(0.6);
      @include transition(all 0.28s ease);
      i,
      h1,
      h2 {
        color: $white-color;
        @include opacity(1);
        @include transition(all 0.28s ease);
      }
    }
  }
  & .view-more {
    display: none;
    @include transition(all 0.28s ease);
  }
  &.plus .a5 .view-only {
    display: none;
    @include transition(all 0.28s ease);
  }
  &.plus .a5 .view-more {
    display: block;
    background-color: $theme-color;
    @include opacity(0.7);
    @include transition(all 0.28s ease);
    i,
    h1,
    h2 {
      color: $white-color;
      @include opacity(1);
      @include transition(all 0.28s ease);
    }
  }
  &.plus .a5 .view-more.delete {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    @include opacity(1);
    @include transition(all 0.28s ease);
    .view-btn {
      display: block;
      height: 100%;
      background-color: $theme-color;
      @include opacity(0.7);
      @include transition(all 0.28s ease);
      i,
      h1,
      h2 {
        color: $white-color;
        @include opacity(1);
        @include transition(all 0.28s ease);
      }
      &:hover {
        @include opacity(0.8);
        @include transition(all 0.28s ease);
      }
    }
    .view-btn.delete {
      display: block;
      top: unset;
      bottom: 0;
      height: 0%;
      @include opacity(0);
      @include transition(all 0.28s ease);
    }
    &:hover {
      .view-btn {
        height: 50%;
        @include transition(all 0.28s ease);
        &:hover {
          @include opacity(0.8);
          @include transition(all 0.28s ease);
        }
      }
      .delete {
        @include opacity(0.7);
      }
    }
  }
  &.count-6 {
    grid-template-areas:
      "a0 a1 a2"
      "a3 a4 a5";
    grid-template-columns: 33.33% 33.33% 33.33%;
  }
  &.count-5 {
    grid-template-areas:
      "a0 a0 a1 a1"
      "a2 a3 a3 a4";
    grid-template-columns: 25% 25% 25% 25%;
  }
  &.count-4 {
    grid-template-areas:
      "a0 a1"
      "a2 a3";
    grid-template-columns: 50% 50%;
  }
  &.count-3 {
    grid-template-areas:
      "a0 a0 a1"
      "a0 a0 a2";
    grid-template-columns: 33.33% 33.33% 33.33%;
  }
  &.count-2 {
    grid-template-areas: "a0 a1" "a0 a1";
    grid-template-columns: 50% 50%;
  }
  &.count-1 {
    display: inline !important;
    max-height: 100% !important;

    .img.a0 {
      max-width: auto !important ;
      max-height: 100% !important;
      text-align: left;
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: $row-height * 2 !important;
        -o-object-fit: contain;
        object-fit: contain;
      }
      &.video {
        width: 100%;
        height: 14rem;
        video {
          object-position: center;
        }
      }
    }
  }
  &.count-0 {
    display: none;
  }
  .img.a0 {
    grid-area: a0;
  }
  .img.a1 {
    grid-area: a1;
  }
  .img.a2 {
    grid-area: a2;
  }
  .img.a3 {
    grid-area: a3;
  }
  .img.a4 {
    grid-area: a4;
  }
  .img.a5 {
    grid-area: a5;
  }
}
.review-area .post-gallery {
  grid-auto-rows: 6rem;
}
/* Gallery CSS Ends */

/* Carousel CSS */
.modal-carousel {
  .modal-content {
    height: 90vh;
    border: none;
    background-color: transparent;
    .modal-body {
      background-color: $white-color;
      border-radius: 1rem;
      padding: 0.8rem;
      padding-top: 1.8rem;
      width: 100%;
      height: 100%;
      .close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        font-size: 1rem;
        opacity: 1;
        color: $medium-gray-color;
        z-index: 100;
        &:hover,
        &:focus {
          color: $gray-color;
          opacity: 1;
          outline: none;
          @include transform(scale(1.1));
          @include transition(all 0.28s ease);
        }
      }
      .carousel {
        width: 100%;
        height: calc(100% - 1rem);
        .carousel-inner {
          width: 100%;
          height: 100%;
          .carousel-item {
            width: 100%;
            height: 100%;
            text-align: center;
            img,
            video {
              width: calc(100% - 4rem);
              height: calc(100% - 14px);
              border-radius: 1rem;
              -o-object-fit: contain;
              object-fit: cover;
              &:hover,
              &:focus {
                outline: 0;
                @include box-shadow(0 0 10px rgba(98, 98, 98, 0.3));
              }
            }
          }
        }
        .carousel-control-prev,
        .carousel-control-next {
          color: $medium-gray-color;
          opacity: 1;
          width: 1.5rem;
          font-size: 2rem;
          font-weight: 900;
          @include transition(all 0.28s ease);
          i:before {
            padding: 0;
          }
        }
        .carousel-control-prev:hover,
        .carousel-control-next:hover,
        .carousel-control-prev:focus,
        .carousel-control-next:focus {
          color: $gray-color;
          opacity: 1;
          @include transform(scale(1.1));
          @include transition(all 0.28s ease);
        }
        .carousel-indicators {
          margin-bottom: 0;
          li {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            background-color: $medium-gray-color;
            &.active {
              background-color: $theme-color;
              @include transform(scale(1.1));
              @include transition(all 0.28s ease);
            }
            &:hover,
            &:focus {
              background-color: $gray-color;
              @include transform(scale(1.1));
              @include transition(all 0.28s ease);
            }
          }
        }
      }
    }
  }
}

#viewProfileImgModal {
  background-color: rgba(0, 0, 0, 1);
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: var(--white-color);
  }
  .modal-dialog {
    max-width: 100%;
  }
  .modal-carousel {
    .modal-content {
      height: 90vh;
      border: none;
      background-color: transparent;
      .modal-body {
        background-color: transparent;
        border-radius: 1rem;
        padding: 0.8rem;
        padding-top: 1.8rem;
        width: 100%;
        height: 100%;
        .close {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          font-size: 1rem;
          opacity: 1;
          color: var(--gray-color);
          z-index: 100;
          &:hover,
          &:focus {
            color: var(--dark-gray-color);
            opacity: 1;
            outline: none;
            transform: scale(1.1);
            transition: all 0.28s ease;
          }
        }
        .carousel {
          width: 100%;
          height: calc(100% - 1rem);
          .carousel-inner {
            width: 100%;
            height: 100%;
            .carousel-item {
              width: 100%;
              height: 100%;
              text-align: center;
              padding: 0 1.875rem;
              .media-view-section {
                display: flex;
                justify-content: space-around;
                height: 100%;
                align-items: center;
              }
              img,
              video {
                width: auto !important;
                height: auto !important;
                border-radius: 1rem;
                -o-object-fit: contain;
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
                &:hover,
                &:focus {
                  outline: 0;
                  box-shadow: none !important;
                }
              }
              app-amp-video,
              video {
                width: 100% !important;
                height: 100% !important;
              }
            }
          }
          .carousel-control-prev,
          .carousel-control-next {
            color: var(--white-color);
            opacity: 0.5;
            width: 1.5rem;
            font-size: 2rem;
            font-weight: 900;
            transition: all 0.28s ease;
            i:before {
              padding: 0;
            }
          }
          .carousel-control-prev:hover,
          .carousel-control-next:hover,
          .carousel-control-prev:focus,
          .carousel-control-next:focus {
            color: var(--white-color);
            opacity: 1;
            transform: scale(1.1);
            transition: all 0.28s ease;
          }
          .carousel-indicators {
            margin-bottom: 0;
            li {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              border-top: 1px solid transparent;
              border-bottom: 1px solid transparent;
              background-color: var(--gray-color);
              &.active {
                background-color: var(--dark-gray-color);
                transform: scale(1.1);
                transition: all 0.28s ease;
              }
              &:hover,
              &:focus {
                background-color: var(--dark-gray-color);
                transform: scale(1.1);
                transition: all 0.28s ease;
              }
            }
          }
        }
      }
    }
  }
}

/* Carousel CSS Ends */

/* Mention List */
mention-list {
  .dropdown-menu {
    border-color: $shadow-color;
    border-radius: 0;
  }
}
/* Mention List End*/

.ng-autocomplete {
  width: 100% !important;
}
ng-autocomplete {
  .search-input {
    font-size: 1rem;
    border-radius: 0;
    border: 1px solid var(--table-th-color);
    &:focus {
      @include box-shadow(none);
      outline: none;
    }
  }
  .search-dropdown {
    width: 100%;
    border: 1px solid var(--table-th-color);
    margin-top: 0;
  }
  .autocomplete-container {
    border: 1px solid var(--table-th-color);
    -webkit-box-shadow: none;
    box-shadow: none;
    height: auto;
    font-size: 1rem;
    .input-container input {
      height: auto;
      line-height: 1;
      font-size: 1rem;
      padding: 5px;
    }
    .suggestions-container {
      z-index: 101;
      ul .item {
        &:hover {
          background-color: $white-color !important;
        }
        &:first-child {
          .recent {
            display: block;
            cursor: default;
            border-bottom: 1px solid $shadow-color;
          }
        }
        .recent {
          display: none;
        }
        a {
          color: $theme-color;
          padding: 5px;
          text-align: left;
          &:hover {
            background-color: $shadow-color;
          }
          span {
            color: $medium-gray-color;
          }
        }
      }
    }
    .not-found {
      position: absolute;
      width: 100%;
    }
  }
}

.tab-list,
.tab-header,
.tab-content {
  width: 100%;
}

.tab-list {
  margin-top: 1rem;
  .nav-link {
    color: $medium-gray-color;
    border-radius: 0;
    border: 1px solid $shadow-color;
    &:focus,
    &:hover {
      color: $theme-color;
      outline: none;
      border-radius: 0;
    }
    &.active {
      color: $theme-color;
      background-color: $shadow-color;
      border-radius: 0;
      border: 1px solid $shadow-color;
    }
  }
  .tab-content {
    border: 1px solid $shadow-color;
    border-top: none;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }
}

.Image-radius {
  .avatar-content {
    border-radius: 50% !important;
  }
}
.review:hover {
  border-bottom: 1px solid var(--gray-color);
}
/* Hide Syncfusion trial message */
div[style*="position: fixed"][style*="top: 10px"] {
  display: none !important;
}
