/*======================================*/
/*---------------- Index ---------------*/
/*======================================*/
/*
  1. Theme colors
  2. Main Color
*/

/* Font CSS */
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

/* Theme colors */
$theme-color: #131313;
$gray-color: #080808;
$medium-gray-color: #737373;
$shadow-color: #eaeaea;
$dark-shadow-color: #cdcdcd;
$light-shadow-color: #f3f3f3;
$white-color: #ffffff;

$black-color: $theme-color; //convert to $theme-color
$light-theme-color: $gray-color; //convert to $gray-color
$medium-gray-color: $medium-gray-color; //convert to $medium-gray-color

/* Main Color */
$danger-color: #ff0f1f;
$success-color: #008000;
$warning-color: #ff8800;
$primary-color: #0d47a1;

:root {
  /* Font family */
  --default-font-family: 'Open Sans', sans-serif;

  /* Colors */
  --white-color: #fff;
  --dark-white-color: #f4f4f4;

  --black-color: #161616;

  --light-gray-color: #eaeaea;
  --gray-color: #7e7e7e;
  --dark-gray-color: #131313;

  --light-danger-color: #f44;
  --danger-color: #e00;
  --dark-danger-color: #800;

  --light-success-color: #00c851;
  --success-color: #1aa800;
  --dark-success-color: #007e33;

  --very-light-warning-color: #fdf3e8;
  --light-warning-color: #e4cd00;
  --warning-color: #f80;
  --dark-warning-color: #d70;

  --light-primary-color: #1a67bb;
  --primary-color: #0056b3;
  --dark-primary-color: #004c9e;
  --background-light-gray-onhover:#f6f6f6;
  /* Header CSS */
  --header-height: 3.8rem;

  /* Footer CSS */
  --footer-height: 2.5rem;
  --footer-color: var(--light-gray-color);

  /* Table Color */
  --table-th-color: #cbc9c6;

  /* Background color */
  --background-color: var(--light-gray-color);
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // :root {
  // }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // :root {
  // }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  // :root {
  // }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1920px) {
  // :root {
  // }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 2500px) {
  // :root {
  // }
}
