@import './variables';
@import './mixins';

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  footer.admin-footer {
    height: var(--footer-height);
  }
  .admin-content,
  .profile-content,
  .homepage-content {
    .homepage-logo {
      max-width: 10rem;
      @include aspect-ratio(1200, 400);
    }
  }
  .fix-h-xs-auto {
    height: auto !important;
  }
  .profile-content {
    padding: 0.5rem;
    .whiteboard {
      margin-top: 0;
      margin-bottom: 0;
      min-height: 100%;
    }
  }

  .post-gallery {
    grid-auto-rows: 5rem;
  }

  .tab-list .nav-link {
    width: 100%;
  }

  .navigation-menu .dropdown-menu {
    margin: 0;
    position: fixed !important;
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    z-index: 100;
    .close {
      display: block;
      position: absolute;
      top: 5px;
      right: 10px;
    }
    .extra {
      height: inherit;
    }
  }

  .header-search {
    text-align: right;
    .search-sm {
      float: right;
      display: block;
      i {
        font-size: 1.5rem;
      }
    }
    .search-field {
      display: none;
    }
    &:focus-within,
    &:focus {
      height: 100%;
      width: 100%;
    }
  }
  .layout-footer {
    height: var(--footer-height);
    grid-area: layout-footer;
    z-index: 99;
  }

  .square-form {
    .form-group {
      .form-control {
        font-size: 12px !important;
      }
      label {
        font-size: 12px !important;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .fix-h-sm-auto {
    height: auto !important;
  }
  .square-form {
    .form-group {
      .form-control {
        font-size: 14px !important;
      }
      label {
        font-size: 14px !important;
      }
    }
  }
  .btn-card {
    &:hover,
    &:focus {
      -webkit-transform: scale(1.07);
      transform: scale(1.07);
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .profile-upload .btn-theme {
    font-size: 0.75rem;
    padding: 0 0.75rem !important;
  }
  .square-form {
    .form-group {
      .form-control {
        font-size: 16px !important;
      }
      label {
        font-size: 16px !important;
      }
    }
  }
  .btn-card {
    &:hover,
    &:focus {
      -webkit-transform: scale(1.07);
      transform: scale(1.07);
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .square-form {
    .form-group {
      .form-control {
        font-size: 14px !important;
      }
      label {
        font-size: 14px !important;
      }
    }
  }
  .btn-card {
    &:hover,
    &:focus {
      -webkit-transform: scale(1.07);
      transform: scale(1.07);
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1919.98px) {
  html {
    font-size: 16px !important;
  }
  .square-form {
    .form-group {
      .form-control {
        font-size: 14px !important;
      }
      label {
        font-size: 14px !important;
      }
    }
  }
  .btn-card {
    &:hover,
    &:focus {
      -webkit-transform: scale(1.07);
      transform: scale(1.07);
    }
  }
}

// Very Extra large devices (Television, 1920px and up)
@media (min-width: 1920px) and (max-width: 2499.98px) {
  html {
    font-size: 18px !important;
  }
  .container {
    max-width: 80%;
  }
  .square-form {
    .form-group {
      .form-control {
        font-size: 16px !important;
      }
      label {
        font-size: 16px !important;
      }
    }
  }
  .btn-card {
    &:hover,
    &:focus {
      -webkit-transform: scale(1.07);
      transform: scale(1.07);
    }
  }
}

// Very Extra large devices (Television, 2500px and up)
@media (min-width: 2500px) {
  html {
    font-size: 20px !important;
  }
  .container {
    max-width: 80%;
  }
  .square-form {
    .form-group {
      .form-control {
        font-size: 18px !important;
      }
      label {
        font-size: 18px !important;
      }
    }
  }
  .btn-card {
    &:hover,
    &:focus {
      -webkit-transform: scale(1.07);
      transform: scale(1.07);
    }
  }
}

html {
  font-size: 14px !important;
}

// Extra small devices (portrait phones, less than 576px)
@media (min-width: 575.98px) {
  html {
    font-size: 14px !important;
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 767.98px) {
  html {
    font-size: 14px !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
  html {
    font-size: 16px !important;
  }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {
  html {
    font-size: 16px !important;
  }
}

@media (min-width: 1799.98px) {
  html {
    zoom: 120%;
  }
}

@media (min-width: 1999.98px) {
  html {
    zoom: 140%;
  }
}
@media (min-width: 2299.98px) {
  html {
    zoom: 150%;
  }
}
@media (min-width: 2599.98px) {
  html {
    zoom: 175%;
  }
}
@media (min-width: 2999.98px) {
  html {
    zoom: 200%;
  }
}
@media (min-width: 3499.98px) {
  html {
    zoom: 250%;
  }
}
@media (min-width: 3999.98px) {
  html {
    zoom: 300%;
  }
}
