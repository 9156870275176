/*
    Flaticon icon font: Flaticon
    Creation date: 15/10/2019 06:45
    */

@font-face {
  font-family: 'Flaticon';
  src: url('../fonts/Flaticon.eot');
  src: url('../fonts/Flaticon.eot?#iefix') format('embedded-opentype'), url('../fonts/Flaticon.woff2') format('woff2'),
    url('../fonts/Flaticon.woff') format('woff'), url('../fonts/Flaticon.ttf') format('truetype'),
    url('../fonts/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
  @include transform(rotateZ(0.03deg));
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('../fonts/Flaticon.svg#Flaticon') format('svg');
  }
}

.fi:before {
  font-size: 1rem default;
  display: inline-block;
  font-family: 'Flaticon';
  font-style: normal;
  font-weight: normal default;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-transform: rotateZ(0.03deg);
  transform: rotateZ(0.03deg);
}

/* _____________________________________________________________________________ */

.flaticon-user:before {
  content: '\f100';
}
.flaticon-user-add:before {
  content: '\f101';
}
.flaticon-user-remove:before {
  content: '\f102';
}
.flaticon-user-true:before {
  content: '\f103';
}
.flaticon-user-pencil:before {
  content: '\f104';
}
.flaticon-login:before {
  content: '\f105';
}
.flaticon-user-edit:before {
  content: '\f106';
}
.flaticon-password:before {
  content: '\f107';
}
.flaticon-eye:before {
  content: '\f108';
}
.flaticon-eye-hide:before {
  content: '\f109';
}
.flaticon-menu:before {
  content: '\f10a';
}
.flaticon-menu-square:before {
  content: '\f10b';
}
.flaticon-list:before {
  content: '\f10c';
}
.flaticon-content:before {
  content: '\f10d';
}
.flaticon-search-user:before {
  content: '\f10e';
}
.flaticon-circle:before {
  content: '\f10f';
}
.flaticon-left-arrow-circle:before {
  content: '\f110';
}
.flaticon-right-arrow-circle:before {
  content: '\f111';
}
.flaticon-up-arrow-circle:before {
  content: '\f112';
}
.flaticon-cancel:before {
  content: '\f113';
}
.flaticon-plus:before {
  content: '\f114';
}
.flaticon-copyright:before {
  content: '\f115';
}
.flaticon-information:before {
  content: '\f116';
}
.flaticon-faq:before {
  content: '\f117';
}
.flaticon-settings:before {
  content: '\f118';
}
.flaticon-delete:before {
  content: '\f119';
}
.flaticon-calendar:before {
  content: '\f11a';
}
.flaticon-info:before {
  content: '\f11b';
}
.flaticon-notification:before {
  content: '\f11c';
}
.flaticon-notification-ring:before {
  content: '\f11d';
}
.flaticon-logout:before {
  content: '\f11e';
}
.flaticon-notification-slash:before {
  content: '\f11f';
}
.flaticon-left-arrow:before {
  content: '\f120';
}
.flaticon-right-arrow:before {
  content: '\f121';
}
.flaticon-down-arrow:before {
  content: '\f122';
}
.flaticon-up-arrow:before {
  content: '\f123';
}
.flaticon-upload:before {
  content: '\f124';
}
.flaticon-download:before {
  content: '\f125';
}
.flaticon-edit:before {
  content: '\f126';
}
.flaticon-attach:before {
  content: '\f127';
}
.flaticon-success:before {
  content: '\f128';
}
.flaticon-search:before {
  content: '\f129';
}
.flaticon-search-2:before {
  content: '\f12a';
}
.flaticon-search-3:before {
  content: '\f12b';
}
.flaticon-filter:before {
  content: '\f12c';
}
.flaticon-comment-3:before {
  content: '\f12d';
}
.flaticon-plus-1:before {
  content: '\f12e';
}
.flaticon-comment-2:before {
  content: '\f12f';
}
.flaticon-comment-1:before {
  content: '\f130';
}
.flaticon-comment:before {
  content: '\f131';
}
.flaticon-shield:before {
  content: '\f132';
}
.flaticon-shield-success:before {
  content: '\f133';
}
.flaticon-shield-failed:before {
  content: '\f134';
}
.flaticon-shield-blocked:before {
  content: '\f135';
}
.flaticon-twitter:before {
  content: '\f136';
}
.flaticon-id:before {
  content: '\f137';
}
.flaticon-credit-card:before {
  content: '\f138';
}
.flaticon-filter-1:before {
  content: '\f139';
}
.flaticon-smartphone:before {
  content: '\f13a';
}
.flaticon-text-message:before {
  content: '\f13b';
}
.flaticon-instagram:before {
  content: '\f13c';
}
.flaticon-twitter-1:before {
  content: '\f13d';
}
.flaticon-text-message-1:before {
  content: '\f13e';
}
.flaticon-credit-card-1:before {
  content: '\f13f';
}
.flaticon-id-verification:before {
  content: '\f140';
}
.flaticon-id-1:before {
  content: '\f141';
}
.flaticon-experian-logo-icon:before {
  content: '\f142';
}
.flaticon-chat:before {
  content: '\f143';
}
.flaticon-chat-1:before {
  content: '\f144';
}
.flaticon-chat-2:before {
  content: '\f145';
}
.flaticon-chat-3:before {
  content: '\f146';
}
.flaticon-origami:before {
  content: '\f147';
}
.flaticon-pointer:before {
  content: '\f148';
}
.flaticon-gps:before {
  content: '\f149';
}
.flaticon-add:before {
  content: '\f14a';
}
.flaticon-remove:before {
  content: '\f14b';
}
.flaticon-warning:before {
  content: '\f14c';
}
.flaticon-speaker:before {
  content: '\f14d';
}
.flaticon-down-arrow-1:before {
  content: '\f14e';
}
.flaticon-up-arrow-1:before {
  content: '\f14f';
}
.flaticon-right-arrow-1:before {
  content: '\f150';
}
.flaticon-left-arrow-1:before {
  content: '\f151';
}
.flaticon-maps-and-flags-1:before {
  content: '\f152';
}
.flaticon-maps-and-flags:before {
  content: '\f153';
}
.flaticon-offic-beg:before {
  content: '\f154';
}
.flaticon-office-desk:before {
  content: '\f155';
}
.flaticon-office-building:before {
  content: '\f156';
}

$font-Flaticon-user: '\f100';
$font-Flaticon-user-add: '\f101';
$font-Flaticon-user-remove: '\f102';
$font-Flaticon-user-true: '\f103';
$font-Flaticon-user-pencil: '\f104';
$font-Flaticon-login: '\f105';
$font-Flaticon-user-edit: '\f106';
$font-Flaticon-password: '\f107';
$font-Flaticon-eye: '\f108';
$font-Flaticon-eye-hide: '\f109';
$font-Flaticon-menu: '\f10a';
$font-Flaticon-menu-square: '\f10b';
$font-Flaticon-list: '\f10c';
$font-Flaticon-content: '\f10d';
$font-Flaticon-search-user: '\f10e';
$font-Flaticon-circle: '\f10f';
$font-Flaticon-left-arrow-circle: '\f110';
$font-Flaticon-right-arrow-circle: '\f111';
$font-Flaticon-up-arrow-circle: '\f112';
$font-Flaticon-cancel: '\f113';
$font-Flaticon-plus: '\f114';
$font-Flaticon-copyright: '\f115';
$font-Flaticon-information: '\f116';
$font-Flaticon-faq: '\f117';
$font-Flaticon-settings: '\f118';
$font-Flaticon-delete: '\f119';
$font-Flaticon-calendar: '\f11a';
$font-Flaticon-info: '\f11b';
$font-Flaticon-notification: '\f11c';
$font-Flaticon-notification-ring: '\f11d';
$font-Flaticon-logout: '\f11e';
$font-Flaticon-notification-slash: '\f11f';
$font-Flaticon-left-arrow: '\f120';
$font-Flaticon-right-arrow: '\f121';
$font-Flaticon-down-arrow: '\f122';
$font-Flaticon-up-arrow: '\f123';
$font-Flaticon-upload: '\f124';
$font-Flaticon-download: '\f125';
$font-Flaticon-edit: '\f126';
$font-Flaticon-attach: '\f127';
$font-Flaticon-success: '\f128';
$font-Flaticon-search: '\f129';
$font-Flaticon-search-2: '\f12a';
$font-Flaticon-search-3: '\f12b';
$font-Flaticon-filter: '\f12c';
$font-Flaticon-comment-3: '\f12d';
$font-Flaticon-plus-1: '\f12e';
$font-Flaticon-comment-2: '\f12f';
$font-Flaticon-comment-1: '\f130';
$font-Flaticon-comment: '\f131';
$font-Flaticon-shield: '\f132';
$font-Flaticon-shield-success: '\f133';
$font-Flaticon-shield-failed: '\f134';
$font-Flaticon-shield-blocked: '\f135';
$font-Flaticon-twitter: '\f136';
$font-Flaticon-id: '\f137';
$font-Flaticon-credit-card: '\f138';
$font-Flaticon-filter-1: '\f139';
$font-Flaticon-smartphone: '\f13a';
$font-Flaticon-text-message: '\f13b';
$font-Flaticon-instagram: '\f13c';
$font-Flaticon-twitter-1: '\f13d';
$font-Flaticon-text-message-1: '\f13e';
$font-Flaticon-credit-card-1: '\f13f';
$font-Flaticon-id-verification: '\f140';
$font-Flaticon-id-1: '\f141';
$font-Flaticon-experian-logo-icon: '\f142';
$font-Flaticon-chat: '\f143';
$font-Flaticon-chat-1: '\f144';
$font-Flaticon-chat-2: '\f145';
$font-Flaticon-chat-3: '\f146';
$font-Flaticon-origami: '\f147';
$font-Flaticon-pointer: '\f148';
$font-Flaticon-gps: '\f149';
$font-Flaticon-add: '\f14a';
$font-Flaticon-remove: '\f14b';
$font-Flaticon-warning: '\f14c';
$font-Flaticon-speaker: '\f14d';
$font-Flaticon-down-arrow-1: '\f14e';
$font-Flaticon-up-arrow-1: '\f14f';
$font-Flaticon-right-arrow-1: '\f150';
$font-Flaticon-left-arrow-1: '\f151';
$font-Flaticon-maps-and-flags-1: '\f152';
$font-Flaticon-maps-and-flags: '\f153';
$font-Flaticon-offic-beg: '\f154';
$font-Flaticon-office-desk: '\f155';
$font-Flaticon-office-building: '\f156';
