/**
 * Example of custom theme cloned from "minimal" and customized
 * Follow the link below for instructions
 * http://manos.malihu.gr/jquery-custom-content-scroller/#styling-section
 **/

/* "minimal" theme */

.mCS-metro.mCSB_scrollTools_vertical {
  right: 0 !important;
  margin: 12px 0;
}

.mCustomScrollBox.mCS-metro + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-metro + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}

.mCS-dir-rtl > .mCSB_outside + .mCS-metro.mCSB_scrollTools_vertical {
  left: 0;
  right: auto;
}

.mCS-metro.mCSB_scrollTools .mCSB_draggerRail {
  background-color: $gray-color;
}

.mCS-metro.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px;
}

.mCS-metro.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}

.mCS-metro.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
//   background-color: #fff;
  background-color: $theme-color;
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-metro.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-metro.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
//   background-color: #fff;
  background-color: $theme-color;
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

/* customization */

.mCS-metro.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
  border-radius: 0px;
  background-color: $theme-color;
}

.mCS-metro.mCSB_scrollTools_vertical {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mCS-metro.mCSB_outside > .mCSB_container {
    //null
    margin-right: 0;
}

.mCSB_inside > .mCSB_container {
    margin-right: 0;
}