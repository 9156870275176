/*======================================*/
/*---------------- Index ---------------*/
/*======================================*/
/*
  1. Box maxin [ box(width0, height) ]
  2. Font-face maxin [ font-face($font-name, $file-name) ]
  3. Arrow maxin [ arrow(direction: down, size: 5px, color: #555) ]
  4. Aspect-ratio maxin [ width, height ]
*/

// 1. Box maxin
@mixin box($width, $height) {
  width: $width;
  height: $height;
}

// 2. Font-face maxin
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix') format('embedded-opentype'), url($file-name + '.woff') format('woff'),
      url($file-name + '.ttf') format('truetype'), url($file-name + '.svg##{$font-name}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

// 3 Arrow maxin
@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}

// 4 Aspect-ratio maxin
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .inner-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// 4 Fix Avtar maxin
@mixin fix-avtar($width, $height) {
  width: $width;
  height: $height;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
// 4 Avtar maxin
@mixin avtar($width, $height) {
  width: $width;
  height: $height;
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
